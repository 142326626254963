import defaultAvatar from '../../img/ava/avatar_02.jpg';

import spinBg0 from '../../img/spin/idle/spin-bg-0.png';
import spinBg1 from '../../img/spin/idle/spin-bg-1.png';
import spinBg2 from '../../img/spin/idle/spin-bg-2.png';
import spinBg3 from '../../img/spin/idle/spin-bg-3.png';
import spinBg4 from '../../img/spin/idle/spin-bg-4.png';
import spinBg5 from '../../img/spin/idle/spin-bg-5.png';
import spinBg6 from '../../img/spin/idle/spin-bg-6.png';
import spinBg7 from '../../img/spin/idle/spin-bg-7.png';
import spinBg8 from '../../img/spin/idle/spin-bg-8.png';
import spinBg9 from '../../img/spin/idle/spin-bg-9.png';
import spinBg10 from '../../img/spin/idle/spin-bg-10.png';
import spinBg11 from '../../img/spin/idle/spin-bg-11.png';
import spinBg12 from '../../img/spin/idle/spin-bg-12.png';
import spinBg13 from '../../img/spin/idle/spin-bg-13.png';
import spinBg14 from '../../img/spin/idle/spin-bg-14.png';
import spinBg15 from '../../img/spin/idle/spin-bg-15.png';
import spinBg16 from '../../img/spin/idle/spin-bg-16.png';
import spinBg17 from '../../img/spin/idle/spin-bg-17.png';
import spinBg18 from '../../img/spin/idle/spin-bg-18.png';
import spinBg19 from '../../img/spin/idle/spin-bg-19.png';
import spinBg20 from '../../img/spin/idle/spin-bg-20.png';
import spinBg21 from '../../img/spin/idle/spin-bg-21.png';
import spinBg22 from '../../img/spin/idle/spin-bg-22.png';
import spinBg23 from '../../img/spin/idle/spin-bg-23.png';


const spinBgArray = [spinBg0, spinBg1, spinBg2, spinBg3, spinBg4, spinBg5, spinBg6, spinBg7,
                      spinBg8, spinBg9, spinBg10, spinBg11, spinBg12, spinBg13, spinBg14, spinBg15,
                      spinBg16, spinBg17, spinBg18, spinBg19, spinBg20, spinBg21, spinBg22, spinBg23];

export default class Util {

    static genNewId = () => {
        return new Date().getTime();
    }

    static addStr = (str, index, stringToAdd) => {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }

    static getDefaultAvatar = () => {
        return defaultAvatar;
    }

    static validateEmail = (str) => {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (str.match(regexEmail)) {
            return true; 
        } else {
            return false; 
        }

    }

    static validatePhoneNumber = (str) => {
        let regexPhoneNumber = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;
        if (str.match(regexPhoneNumber)) {
            return true; 
        } else {
            return false; 
        }
    }

    static getToday = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return today;
    }

    static getFirstOfWeek = () => {
        var resultDay = this.getToday();
        var day = resultDay.getDay() || 7;
        if (day !== 1) {
            resultDay.setHours(-24 * (day - 1));
        }

        return resultDay;
    }

    static getFirstOfMonth = () => {
        var today = this.getToday();
        var resultDay = new Date(today.getFullYear(), today.getMonth(), 1);

        return resultDay;
    }

    static getLastOfDay = (day) => {

        console.log('=========day===========', day);

        var resultDay = new Date(day);
        resultDay.setDate(resultDay.getDate() + 1);
        resultDay.setHours(0);
        resultDay.setMinutes(0);
        resultDay.setSeconds(0);
        return resultDay;
    }
}

  export function customStyle(index) {
    return { '--item-nb': index };
  }

  export function higherPrizeStyle(index) {
    return { '--higher-prize-nb': index };
  }
  
  export function getWheelVars(length, selectedIndex) {
    return {
      '--nb-item': length,
      '--selected-item': selectedIndex,
    };
  }
  
  export function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
  }
  
  export function getFormattedResult(selectedIndex) {
    const currentTimestamp = new Date();
    const resultData = {
      web_client: 'mobile-pwa',
      timestamp: currentTimestamp,
      spin_result_index: selectedIndex,
    };
    return resultData;
  }
  
  export function getPositionFromCenter(event, boxCenterPoint) {
    let clientX, clientY;
    if (event.targetTouches && event.targetTouches[0]) {
      clientX = event.targetTouches[0].pageX;
      clientY = event.targetTouches[0].pageY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }
    const fromBoxCenter = {
      x: clientX - boxCenterPoint.x,
      y: -(clientY - boxCenterPoint.y),
    };
    return fromBoxCenter;
  }


  export function getSpinBgImg(idx) {
    return spinBgArray[(idx + 24) % 24];
  }
  
export function findCeil(arr, r, l, h)
{
    let mid;
    while (l < h)
    {
        mid = l + ((h - l) >> 1); // Same as mid = (l+h)/2
        (r > arr[mid]) ? (l = mid + 1) : (h = mid);
    }
    return (arr[l] >= r) ? l : -1;
}

export function randWithFreq(arr, freq,  n) {
  // Create and fill prefix array
  let prefix= [];
  let i;
  prefix[0] = freq[0];
  for (i = 1; i < n; ++i)
      prefix[i] = prefix[i - 1] + freq[i];

  // prefix[n-1] is sum of all frequencies.
  // Generate a random number with
  // value from 1 to this sum
  let r = Math.floor((Math.random()* prefix[n - 1])) + 1;

  // Find index of ceiling of r in prefix array
  let indexc = findCeil(prefix, r, 0, n - 1);
  return arr[indexc];
}

export function randWithFreqIndex(arr, freq,  n) {
  // Create and fill prefix array
  let prefix= [];
  let i;
  prefix[0] = freq[0];
  for (i = 1; i < n; ++i)
      prefix[i] = prefix[i - 1] + freq[i];

  // prefix[n-1] is sum of all frequencies.
  // Generate a random number with
  // value from 1 to this sum
  let r = Math.floor((Math.random()* prefix[n - 1])) + 1;

  // Find index of ceiling of r in prefix array
  let indexc = findCeil(prefix, r, 0, n - 1);
  indexc = (indexc + n) % n;
  return indexc;
}


export function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export function getLogoWithSlug(games, gameSlug) {
  if (games && games.length > 0) {
    const game = games.find(item => item.slug == gameSlug);
    return game?.logo;
  }

  return null;
}